import DispSensorData from "../../Molecules/DispSensorData"
import Image from "../../Atoms/Image"
import Text from "../../Atoms/Text"
import {Grid, GridArea} from "../../../style/Grid"
import {SensorDataWrapper,
    SmallerSensorDataWrapper,
    DisplayPlaceDetailWrapper,
    SensorDataParamWrapper,
    SensorDataParamBiggerWrapper,
    MiniTextWrapper,
    PlaceWrapper,
    OutWrapperBox,
    BiggerImgWrapper
} from "./styledComponents"
import {SensorData,PlaceNameTypeKey, PlaceName} from "../../../states/utilState"
import {styleColor,styleColorWeather,fontColor} from "../../../style/color"
import {TimeZone,Weather} from "../../../states/utilState"
import {lightIcon,darkIcon,IconType, image} from "../../../style/image"

import {
    icon,soundTypeKey,blightTypeKey,co2TypeKey
} from "../../../style/image"
import {palceImg} from "../../../style/image"
import { fontFamily, fontSize } from "../../../style/Font"

type DisplayPlaceDetailProps = {
    placeData:SensorData,
    nowPlace: number,
    weather: keyof typeof Weather,
    time:keyof typeof TimeZone,
}
const DisplayPlaceDetail:React.FC<DisplayPlaceDetailProps> = (props) => {
    const {placeData, weather, nowPlace,time} = {...props}
    const timetype = TimeZone[time] as string
    const weatherTyep = Weather[weather] as string
    return (
        <OutWrapperBox bgColor={styleColor[weatherTyep][timetype].bg}>
            <DisplayPlaceDetailWrapper >
                <Grid
                    columns={["100%"]}
                    rows={
                        [
                            "70px",
                            "1fr"
                        ]
                    }
                    areas={[
                        ["space-disp-place"],
                        ["map-disp-place"]
                    ]}
                >
                    <GridArea name="map-disp-place">
                        <PlaceWrapper>
                            <Image
                                url={palceImg[PlaceName[PlaceNameTypeKey[nowPlace]]]}
                                height="auto"
                                width={140}
                                shape="SQUARE"
                                isFitContent={false}
                            />
                            <Text
                                text={placeData.placeName}
                                style={{
                                    fontSize:"LARGER",
                                    fontColor:"dark",
                                    fontFamily:"Ja",
                                    bold: true
                                }}
                            />
                        </PlaceWrapper>
                    </GridArea>
                </Grid>
                <BiggerFont
                    label="温度"
                    subText = "℃"
                    param = {placeData.temp}
                    image = {icon.dark.temp}
                    fontColor= "green"
                    weather = {weather}
                    time = {time}
                />
                <BiggerFont
                    label="湿度"
                    subText = "％"
                    param = {placeData.hum}
                    image = {icon.dark.hum}
                    fontColor= "blue"
                    weather = {weather}
                    time = {time}
                />
                <SmallerFont
                    label="音量"
                    subText = "db"
                    param = {placeData.volume.db}
                    image = {icon.dark.sound[soundTypeKey[placeData.volume.level]]}
                    weather = {weather}
                    time = {time}
                />
                <SmallerFont
                    label="明るさ"
                    subText = "lux"
                    param = {placeData.bright.lx}
                    image = {icon.dark.blight[blightTypeKey[placeData.bright.level]]}
                    weather = {weather}
                    time = {time}
                />
                <SmallerFont
                    label="CO₂濃度"
                    subText = "ppm"
                    param = {placeData.co2.ppm}
                    image = {icon.dark.co2[co2TypeKey[placeData.co2.level]]}
                    weather = {weather}
                    time = {time}
                />
            </DisplayPlaceDetailWrapper>
        </OutWrapperBox>
    )
}

export default DisplayPlaceDetail

type SmallerFontProps = {
    label: string,
    subText: string,
    param: number,
    image: string,
    weather: keyof typeof Weather,
    time: keyof typeof TimeZone
}
const SmallerFont: React.FC<SmallerFontProps> = (props) => {
    const {label,subText,param, image, weather, time } = {...props}
    return (
        <DispSensorData
            text={label}
            weather={weather}
            time={time}
            boxWidth={340}
            bold={true}
        >
            <SmallerSensorDataWrapper>
                <Image
                        url={image}
                        height={141}
                        width= "auto"
                        isFitContent={true}
                        shape="SQUARE"
                    />
                <SensorDataParamWrapper>
                    <Text
                        text={String(Math.floor(param))}
                        style={{
                            fontSize:"NORMAL",
                            fontColor:"dark",
                            fontFamily:"Eng"
                        }}
                    />
                    <Text
                        text={subText}
                        style={{
                            fontSize:"MEDIUM",
                            fontColor:"dark",
                            fontFamily:"Eng"
                        }}
                    />
                </SensorDataParamWrapper>
            </SmallerSensorDataWrapper>
        </DispSensorData>
    )
}

type BiggerFontProps = {
    label: string,
    subText: string,
    param: number,
    image: string,
    fontColor: keyof typeof fontColor,
    weather: keyof typeof Weather,
    time: keyof typeof TimeZone
}
const BiggerFont: React.FC<BiggerFontProps> = (props) => {
    const {label,subText,param, image, weather, time } = {...props}
    const boxHeight = 280
    return (
        <DispSensorData
            text={label}
            weather={weather}
            time={time}
            boxWidth={460}
            bold={true}
        >
            <SensorDataWrapper height={boxHeight}>
                <BiggerImgWrapper>
                    <Image
                        url={image}
                        height="auto"
                        width={label === "温度" ? 50 : 61}
                        isFitContent={true}
                        shape="SQUARE"
                        />
                </BiggerImgWrapper>
                <SensorDataParamBiggerWrapper>
                <p>
                        <span style={{
                            fontSize:fontSize.BIGGER+"px",
                            fontFamily: fontFamily.Eng,
                            color: fontColor[props.fontColor],
                            fontWeight: "bold"
                            }}>
                            {String(Math.floor(param))}
                        </span>
                        <span style={{
                            fontSize:fontSize.MEDIUM+"px",
                            fontFamily: fontFamily.Eng,
                            color: fontColor[props.fontColor],
                            fontWeight: "bold"
                            }}>
                            {subText}
                        </span>
                    </p>
                    {/* <Text
                        text={String(Math.floor(param))}
                        style={{
                            fontSize:"BIGGER",
                            fontColor:props.fontColor,
                            fontFamily:"Eng",
                            bold:true,
                            letterSpacing:-12
                        }}
                    />
                    <MiniTextWrapper>
                        <Text
                            text={subText}
                            style={{
                                fontSize:"MEDIUM",
                                fontColor:props.fontColor,
                                fontFamily:"Eng",
                                elm:-1.3
                            }}
                        />
                    </MiniTextWrapper> */}
                </SensorDataParamBiggerWrapper>
            </SensorDataWrapper>
        </DispSensorData>
    )
}