import {palceImg} from "../style/image"

export type OfficeDataState = {
    sensorDatas: SensorData[],
    weather: number,
    timeZone: number,
    isDataexist: boolean
}

export const PlaceTypeKey:{[key:number]: keyof typeof palceImg} = {
    [0]:"meeting1",
    [1]:"meeting2",
    [2]:"meeting3",
    [4]:"meeting4",
    [5]:"receptionMeeting",
    [6]:"webMeeting",
    [7]:"reflesh",
}

export type FetchOfficeDataSuccess = {
    sensorInfo: FetchSensorData[],
    weather: number,
    timeZone: number
}

export type SensorData = {
    placeName: string,
    temp: number,
    hum: number,
    comfort: number,
    volume: VolumeProps
    bright: BrightProps
    co2: CO2Props
}

export type FetchSensorData = {
    placeName: string,
    temp: number,
    hum: number,
    comfort: number,
    volume: VolumeProps
    light: BrightProps
    co2: CO2Props
}

export type VolumeProps = {
    db: number,
    level: number,
    msg: string
}

export type BrightProps ={
    lx: number,
    level: number,
    msg: string
}

export type CO2Props = {
    level: number,
    ppm: number,
    msg: string
}

export const TimeZone = {
    DayTime:"dayTime",
    Evening:"evening",
    Night:"night"
} as const

export const PlaceName = {
    meeting1:"meeting1",
    meeting2:"meeting2",
    meeting3:"meeting3",
    meeting4:"meeting4",
    reception:"reception",
    webMeeting:"webMeeting",
    refreshSpace:"refreshSpace",
    meetingBooth:"meetingBooth",
    openSpace1:"openSpace1",
    openSpace2:"openSpace2",
    openSpace3:"openSpace3",
    openSpace4:"openSpace4",
    highTable:"highTable",
}

export const PlaceNameTypeKey:{[key:number]:keyof typeof PlaceName} = {
    [0]:"meeting1",
    [1]:"meeting2",
    [2]:"meeting3",
    [3]:"meeting4",
    [4]:"reception",
    [5]:"webMeeting",
    [6]:"refreshSpace",
    [7]:"meetingBooth",
    [8]:"openSpace1",
    [9]:"openSpace2",
    [10]:"openSpace3",
    [11]:"openSpace4",
    [12]:"highTable",
} as const

export const WeatherTypeKey: {[key:number]: keyof typeof Weather} = {
    [0]: "Sunny",
    [1]: "Rain",
    [2]: "Cloud",
    [3]: "Snow"
}

export const TimeZoneTypeKey: {[key:number]: keyof typeof TimeZone} = {
    [0]: "DayTime",
    [1]: "Evening",
    [2]: "Night"
}

export const Weather = {
    Sunny: "sunnyColor",
    Cloud: "cloundColor",
    Rain: "rainColor",
    Snow: "snowColor"
}