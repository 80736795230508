import styled from "styled-components"
import {Position, Offset} from "./index"

export const PlaceDataComponentWrapper = styled.div`
position: relative;
display: inline-block;
/* flex-direction: column;
flex-wrap: wrap;
align-items: center;
align-content: center;
justify-content: flex-start; */
width: 100%;
height: 100%;
`


export const PlaceDataContainer = styled.div<Position>(props=>`
    position: absolute;
    top: ${props.top === "auto" ? "auto" : props.top+ "px"};
    left: ${props.left === "auto" ? "auto" : props.left+"px"};
    bottom: ${props.bottom === "auto" ? "auto" : props.bottom+"px"};
    right: ${props.right === "auto" ? "auto" : props.right+"px"};
    display: flex;
    height: fit-content;
    width: fit-content;
    /* align-items: center; */
    /* justify-content: center; */
`)

export const PlaceDataContent = styled.div`
    position: relative;
    height: fit-content;
    width: fit-content;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
`

export const PlaceDataContentWrapper = styled.div<Offset>(props=>`
    position: absolute;
    height: fit-content;
    width: fit-content;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(${props.left + "%"}, ${props.top + "%"});
    /* align-items: center; */
    /* justify-content: center; */
`)