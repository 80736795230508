import DispSensorData from "../../Molecules/DispSensorData"
import Image from "../../Atoms/Image"
import {
    PlaceDataContainer,
    PlaceDataComponentWrapper,
    PlaceDataContentWrapper,
    PlaceDataContent
} from "./styledComponents"
import {SensorData,PlaceNameTypeKey, PlaceName} from "../../../states/utilState"
import {styleColor,styleColorWeather} from "../../../style/color"
import {TimeZone,Weather} from "../../../states/utilState"
import {lightIcon,darkIcon,IconType} from "../../../style/image"
import Widget from "../../Atoms/Widget"
import {Grid, GridArea} from "../../../style/Grid"
import MapImage from "../../Molecules/MapImage"
import StateOfComfort from "../../Molecules/StateOfComfort"
import Text from "../../Atoms/Text"
import {palceImg} from "../../../style/image"
type DataTableProps = {
    placeData:SensorData[],
    nowPlace: number,
    weather: keyof typeof Weather,
    time:keyof typeof TimeZone,
}
const ComfortMap:React.FC<DataTableProps> = (props) => {
    const {placeData, weather, nowPlace,time} = {...props}
    // console.log("map now place", nowPlace)
    return (
        <PlaceDataComponentWrapper>
            {makePlaceDataComponent(placeData, nowPlace)}
        </PlaceDataComponentWrapper>
    )
}

export default ComfortMap

const makePlaceDataComponent = (datas: SensorData[], nowPlace: number) => {
    return datas.map((data, index)=>{
        return (
            <PlaceDataContainer {...PositionOfComfortPins[index]}>
                <PlaceDataContent>
                    <PlaceDataContentWrapper {...OffsetOfCoverImg[index]}>
                        <StateOfComfort
                            comfortLevel={data.comfort}
                            noisyLevel={data.volume.level}
                            size={180}
                        />
                    </PlaceDataContentWrapper>
                    {
                        nowPlace === index ? 
                    <PlaceDataContentWrapper {...OffsetOfCoverImg[index]}>
                        <Image
                            url={palceImg[PlaceName[PlaceNameTypeKey[index]]]}
                            width={SizeOfCoverImg[index]}
                            height="fit-content"
                            isFitContent={false}
                            shape="SQUARE"
                        />
                    </PlaceDataContentWrapper> 
                    :<></>
                    }
                </PlaceDataContent>
            </PlaceDataContainer>
        )
    })
}

// Positionはleft, rightのどちらかをnumberで指定、もう片方をautoで指定。
// topとbottomも同様に指定する。
export type Position = {
    left: number | "auto",
    right: number | "auto",
    top: number | "auto",
    bottom: number | "auto",
}

export type Offset = {
    left: number,
    top: number,
}

export const PositionOfComfortPins:{[key:number]: Position} = {
    [0]: {left: 434, right: "auto", top: 305, bottom: "auto"},
    [1]: {left: 434, right: "auto", top: 610, bottom: "auto"},
    [2]: {left: 434, right: "auto", top: 930, bottom: "auto"},
    [3]: {left: 1064, right: "auto", top: 317, bottom: "auto"},
    [4]: {left: 434, right: "auto", top: 1255, bottom: "auto"},
    [5]: {left: 1261, right: "auto", top: 525, bottom: "auto"},
    [6]: {left: 1292, right: "auto", top: 1225, bottom: "auto"},
    [7]: {left: 1736, right: "auto", top: 617, bottom: "auto"},
    [8]: {left: 2055, right: "auto", top: 1075, bottom: "auto"},
    [9]: {left: 2502, right: "auto", top: 545, bottom: "auto"},
    [10]: {left: 2657, right: "auto", top: 1075, bottom: "auto"},
    [11]: {left: 3172, right: "auto", top: 1075, bottom: "auto"},
    [12]: {left: 3125, right: "auto", top: 515, bottom: "auto"}
}

const SizeOfCoverImg:{[key:number]: number} = {
    [0]: 400,
    [1]: 415,
    [2]: 428,
    [3]: 350,
    [4]: 445,
    [5]: 390,
    [6]: 796,
    [7]: 500,
    [8]: 500,
    [9]: 500,
    [10]: 500,
    [11]: 500,
    [12]: 500,
}

export const OffsetOfCoverImg:{[key:number]: Offset} = {
    [0]: {left: -36, top: -50},
    [1]: {left: -34, top: -52},
    [2]: {left: -34, top: -52},
    [3]: {left: -40, top: -35},
    [4]: {left: -32, top: -52},
    [5]: {left: -50, top: -50},
    [6]: {left: -48, top: -48},
    [7]: {left: -50, top: -50},
    [8]: {left: -50, top: -50},
    [9]: {left: -50, top: -50},
    [10]: {left: -50, top: -50},
    [11]: {left: -50, top: -50},
    [12]: {left: -50, top: -50},
}
